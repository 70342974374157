import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//重复请求相同路由时的报错，不再提示
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export const constantRouter = [
    {
        path: '/',
        name: 'qabillar',
        redirect: '/share',
    },
    {
        path: '/openios',
        component: () => import('@/views/open-ios/open-ios'),
        name: 'open-ios'
    },
    {
        path: '/share',
        name: 'share',
        component: () => import('@/views/home/share'),
    },
    // {
    //     //主页
    //     path: '/home',
    //     component: () => import('@/views/home/index'),
    //     name: 'Home',
    //     redirect: '/home/inquiryProgram',
    //     children: [
    //         {
    //             //用户详情
    //             path: 'userInfo',
    //             component: () => import('@/views/home/user/user-info'),
    //             name: 'user-info'
    //         },
    //     ]
    // },
    {
        path: '/404',
        component: () => import('@/views/404')
    },

    // 404 page must be placed at the end !!!
    {
        path: '*',
        redirect: '/404'
    }
]

export default new Router({
    mode: 'history',
    routes: constantRouter
})
