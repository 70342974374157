export default {
    message: {
        'special': 'مەخسۇس تېما',
        'clickToLearnMore':'تېخىمۇ كۆپ مۇناسىۋەتلىك ئۇچۇرلار',
        'recommendation': 'ئاۋات تەۋسىيە',
        'clickToSeeMore': 'تېخىمۇ كۆپنى كۆرۈش',
        'openApp': 'APPنى ئېچىش',
        'fabulous': 'يۈرەك',
        'appName': 'قابىللار سانلىق مەلۇماتى',
        'appIntroduce': '7*24سائەتلىك ئىقتىساد ئۇچۇرلىرى سۇپىسى',
        'chargingTips':'ھەقلىق مەزمۇن،سېتىۋېلىپ داۋامىنى كۆرۈڭ',
        'purchase':'سېتىۋېلىش',
        'tips':'ئەسكەرتىش',
        'tipsContent':'تور كۆرگۈچتە ئېچىڭ',
        'confirm':'جەزىملەش',
        'qabillarAudio':'قابىللار قانىلى'
    }
};