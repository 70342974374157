<template>
  <div id="app">
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <router-view/>
  </div>
</template>

<script>
    window.onload = function() {
        document.addEventListener('touchstart', function(event) {
            if (event.touches.length > 1) {
                event.preventDefault()
            }
        });
        document.addEventListener('gesturestart', function(event) {
            event.preventDefault()
        })
    };

    export default {
        name: 'App',
        data() {
            return {}
        },
        mounted() {},
        methods: {},
        // components: {
        //   HelloWorld
        // }
    }
</script>

<style>

</style>
