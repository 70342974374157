import Vue from 'vue';
import VueI18n from 'vue-i18n';
import scLocal from './language-sc'
import uyLocal from './language-uy'
Vue.use(VueI18n);
const messages = {
    en: {
        ...uyLocal
    },
    sc: {
        ...scLocal
    }
}

export function getQuery() {
    //默认语言
    var language = "uy"

    //url中获取语言
    var url = window.location.href ;//获取当前url
    // var dz_url = url.split('#')[0];//获取#/之前的字符串
    if (url.split('?').length > 1){
        var cs = url.split('?')[1];//获取?之后的参数字符串
        var arr = cs.split('&');//参数字符串分割为数组
        arr.forEach(function (val) {
            //切割=两边的数据
            var arr1 = val.split('=');
            if (arr1[0] === 'language') {
                // console.info(arr1[1])
                language = arr1[1];
            }
        });
    }
    return language
}

const i18n = new VueI18n({
    locale: getQuery(),
    messages,
});

export default i18n;