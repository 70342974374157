import Vue from 'vue'
import App from './App.vue'
//路由
import router from './router'
import axios from 'axios'
import 'jquery'
//引入element-ui
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
//全局css
import '@/styles/index.scss'
//语言包
import '@/styles/font/font.css'
//国际化
import i18n from './assets/language/index'

Vue.use(Element)
Vue.config.productionTip = false
//全局注册，使用方法为:this.$axios
Vue.prototype.$axios = axios
//配置请求的根路径
axios.defaults.baseURL = '/api'

new Vue({
    render: h => h(App),
    router,
    i18n
}).$mount('#app')
