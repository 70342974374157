export default {
    message: {
        'special': '专题',
        'clickToLearnMore':'点击即可了解更多相关信息',
        'recommendation': '热门推荐',
        'clickToSeeMore': '点击查看更多',
        'openApp': '打开app',
        'fabulous': '赞',
        'appName': '凯贝拉数据',
        'appIntroduce': '7*24小时提供专业的财经快讯',
        'chargingTips':'收费内容，点击购买继续阅读此文章',
        'purchase':'购买',
        'tips':'提示',
        'tipsContent':'请在浏览器中打开',
        'confirm':'确定',
        'qabillarAudio':'凯贝拉电台'
    }
};